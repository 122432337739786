* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-share.css');


:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  --primary-color: #DC1F5F;
  --secondary-color: #940032;
  --primary-black:#212529;
  --secondary-black: rgba(33, 37, 41, 0.7);
  --background-gradient: linear-gradient(90deg, #EC008C 0%, #FC6767 100%);
  --background-color: #FEE9F3;
  --body-fonts: "Josefin Sans", sans-serif;
  --line-height: 1.5;
}


body {
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.desktop_hide{
  display: none;
}

.mob_hide{
  display: block;
}

.hide_text_mob{
  display: inline;
}


.mt-100 {
  margin-top: 100px;
}

.text-center{
  text-align: center;
}

.modal-backdrop.show {
  z-index: -1;
}
.modal {
  background-color: #00000082;
  z-index: 9999;
}
.modal-content {
  background: var(--background-color);
  border-radius: 20px;
}
.close_btn {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  right: -14px;
  top: -13px;
}
.close_btn .btn-close {
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
  padding: 6px !important;
}
.mt40 {
  margin-top: 30px;
}
.modal-header {
  border-bottom: none !important;
  padding: 11px !important;
}
.modal-dialog {
  width: 404px !important;
}
.modal-body {
  padding: 23px 23px 31px !important;
}
.modal_contact_details {
  margin-top: 20px;
}
.modal_contact_details a {
  display: block;
  color: #222;
  text-decoration: none;
  font-size: 13px;
}
.btn-close:focus {
  box-shadow: none !important;
}
.modal_title {
  font-size: 22px;
  font-weight: 500;
}
.modal_input input {
  padding: 8px !important;
  font-size: 13px;
}
.modal_btn button {
  padding: 7px !important;
  font-size: 13px;
}
.modal_input select {
  padding: 9px 9px 9px 4px !important;
  font-size: 13px;
}
.error_message {
  color: red;
  font-size: 11px;
  margin-left: 3px;
  position: absolute;
}

.modal_btn{
  margin-top: 40px;
}


@media screen and (max-width: 768px) {
  .modal-dialog {
    width: 94% !important;
  }
  .modal_item {
    margin-top: 0;
  }

  .modal_btn{
    margin-top: 0px;
  }

  .desktop_hide{
    display: block;
  }

  .mob_hide {
    display: none;
  }

  .hide_text_mob{
    display: none;
}

  .mt-100 {
    margin-top: 60px;
  }
}