.footer_div {
    padding-top: 60px;
    padding-bottom: 20px;
    background: var(--background-gradient);
}

.footer_about img {
    height: 50px;
}

.footer_about p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: #fff;
    margin-top: 20px;
}

.footer_links {
    margin-top: 60px;
    margin-left: 60px;
}

.footer_links h5 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}

.footer_links ul {
    margin: 0;
    padding: 0px;
}

.footer_links li {
    list-style: none;
    margin-bottom: 10px;
}

.footer_links a {
    color: #fff;
}

.footer_address {
    margin-top: 60px;
    margin-left: 60px;
}

.footer_address h5 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}

.footer_address p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 10px;
}

.footer_address img {
    height: 20px;
    margin-right: 10px;
}

.footer_line {
    color: #fff;
    border-top: 1px solid #fff;
    opacity: 0.51;
}

.footer_bottom {
    font-size: 13px;
    line-height: 23px;
    font-weight: 400;
    text-align: center;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .footer_div {
        padding-top: 40px;
        padding-bottom: 10px;
    }

    .footer_about img {
        height: 35px;
    }

    .footer_about p {
        font-size: 15px;
        line-height: 25px;
        margin-top: 10px;
    }


    .footer_address {
        margin: 0;
    }

    .footer_address h5 {
        font-size: 18px;
        line-height: 28px;
    }
    
    .footer_address p {
        font-size: 15px;
        line-height: 25px;
    }

    .footer_address img {
        height: 17px;
        margin-right: 6px;
    }

    .footer_bottom {
        font-size: 10px;
        line-height: 20px;
    }

    .footer_bottom p{
        margin: 0;
    }

    .footer_line {
        margin: 10px 0px;
    }
    
}