.formDiv:first-child {
    margin-top: 30px;
  }
  
  .formDiv {
    margin-top: 40px;
  }
  
  .formItem {
    position: relative;
  }
  
  .formItem .requiredItem {
    color: red;
    font-size: 12px;
    margin-left: 10px;
    position: absolute;
    top: 50px;
  }
  
  .formItem input {
    width: 100%;
    padding: 10px;
    box-shadow: 0px 0px 16px 0px rgba(33, 37, 41, 0.05);
    border: none;
    border-radius: 10px;
  }

  .formItem textarea {
    width: 100%;
    padding: 10px;
    box-shadow: 0px 0px 16px 0px rgba(33, 37, 41, 0.05);
    border: none;
    border-radius: 10px;
  }
  
  .formItem .selectItem {
    border: none !important;
    width: 100%;
    padding: 10px;
    box-shadow: 0px 0px 16px 0px rgba(33, 37, 41, 0.05);
    border-radius: 10px;
    background: #fff;
    color: #8088a1;
  }
  
  .formItem .numberBtn {
    width: 100%;
    padding: 7px;
    border-radius: 10px;
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary-color) !important;
    --bs-btn-border-color: var(--primary-color) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary-color) !important;
    --bs-btn-hover-border-color: var(--primary-color) !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary-color) !important;
    --bs-btn-active-border-color: var(--primary-color) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--primary-color) !important;
    --bs-btn-disabled-border-color: var(--primary-color) !important;
  }

  @media screen and (max-width: 768px){
    .formDiv:first-child {
        margin-top: -20px;
      }
      .formItem input, .formItem .selectItem {
        -webkit-box-shadow: 0px 0px 16px 0px rgba(33, 37, 41, 0.09) !important;
        box-shadow: 0px 0px 16px 0px rgba(33, 37, 41, 0.09) !important;
        border: 1px solid #eae0e0 !important;
        padding: 8px !important;
        height: 40px !important;
        color: #8088a1;
        font-size: 13px;
      }
      .formDiv {
        margin-top: 0;
      }
      .formItem {
        margin-top: 30px;
      }
      .formItem .requiredItem {
        top: 40px;
        font-size: 10px;
      }
      .formItem .numberBtn {
        font-size: 13px;
        padding: 10px;
      }
  }