.about_text {
    margin-top: 60px;
    text-align: center;
}

.about_text h1 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    color: var(--secondary-color);
}

.about_text p {
    font-size: 18px;
    font-weight: 300;
    color: var(--primary-black);
}

.about_img {
    position: relative;
}

.about_img img {
    position: absolute;
    top: 39px;
}

.text_heading {
    text-align: center;
}

.text_heading h2 {
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--secondary-color);
}

.text_heading p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: var(--primary-black);
}

.services{
    margin-top: 410px;
}

.services_main{
    margin-top: 40px;
}

.service_div {
    position: relative;
}

.service_div::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 2px solid white;
    pointer-events: none;
    border-radius: 20px;
}

.service_div img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.services_main h5 {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: var(--primary-color);
    margin-top: 20px;
}

.services_main p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: var(--primary-black);
}


@media screen and (max-width: 768px) {
    .about_text h1 {
        font-size: 24px;
        line-height: 34px;
    }
    
    .about_text p {
        font-size: 16px;
        font-weight: 300;
    }

    .about_img {
        position: static;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .about_img img {
        position: static;
        max-width: 783px;
        margin-top: 24px;
    }

    .services{
        margin-top: 60px;
    }

    .text_heading h2 {
        font-size: 24px;
        line-height: 34px;
        text-align: center;
    }
    
    .text_heading p {
        font-size: 16px;
        line-height: 26px;
        margin: 0;
    }

    .services_main{
        margin-top: 0;
    }

    .service_div{
        margin-top: 40px;
    }

    .services_main h5 {
        font-size: 18px;
        line-height: 28px;
        margin-top: 20px;
    }
    
    .services_main p {
        font-size: 16px;
        line-height: 26px;
        text-align: justify;
        margin: 0;
    }
}