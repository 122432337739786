.portfolio_text {
    margin-top: 60px;
    text-align: center;
}

.portfolio_text h1 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    color: var(--secondary-color);
}

.portfolio_text p {
    font-size: 18px;
    font-weight: 300;
    color: var(--primary-black);
}

.portfolio_img {
    margin-top: 20px;
}

.portfolio_img img {
    height: 300px;
    margin-top: 15px;
}

.text_heading {
    text-align: center;
}

.text_heading h2 {
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--secondary-color);
}

.text_heading p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: var(--primary-black);
}

.home_gallery {
    margin-top: 40px;
}

@media screen and (max-width: 768px) {
    .portfolio_text h1 {
        font-size: 24px;
        line-height: 34px;
    }

    .portfolio_text p {
        font-size: 16px;
        font-weight: 300;
    }

    .text_heading h2 {
        font-size: 24px;
        line-height: 34px;
        text-align: center;
    }

    .text_heading p {
        font-size: 16px;
        line-height: 26px;
        margin: 0;
    }

    .portfolio_img {
        margin-top: 26px;
    }

    .portfolio_img img {
        height: 180px;
        margin-top: 0;
    }
}