.home_floral_pattern {
  position: relative;
}

.home_floral_pattern::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 731px;
  background:
    linear-gradient(90deg, rgba(236, 0, 140, 0.1) 0%, rgba(252, 103, 103, 0.1) 100%),
    url('../../assets/pattern.svg') no-repeat center center / cover;
  z-index: -1;
} 

.navbar {
  padding: 40px 120px;
  background-color: transparent !important;
}

.navbar .navbarRight p {
  margin-bottom: 0px;
}

.navbarRight {
  width: 100%;
  justify-content: end;
}

.navbarItem {
  color: var(--primary-color);
  margin-right: 20px;
}

.logo {
  height: 50px;
}

.visitBtn {
  --bs-btn-color: var(--primary-color) !important;
  --bs-btn-border-color: var(--primary-color) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary-color) !important;
  --bs-btn-hover-border-color: var(--primary-color) !important;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary-color) !important;
  --bs-btn-active-border-color: var(--primary-color) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--primary-color) !important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary-color) !important;
  --bs-gradient: none;
  /* background: #fff; */
}

.visitBtn:hover {
  background: var(--primary-color) !important;
}

.visitBtnDark {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  width: 150px;
}

.visitBtnDark:hover {
  background-color: #fff !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

@media (max-width: 768px) {
  .navbarMain {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 80px;
    z-index: 999;
    right: 0px;
    transition: 0.3s;
  }

  .navbar .navbarRight p {
    margin-bottom: 8px;
  }

  .navbar {
    background-color: #fff !important;
    border-radius: 0px;
    padding: 10px 20px;
    border-right: none;
  }

  .logo {
    height: 35px;
  }

  .navbar {
    padding: 10px 0px;
  }

  .navbarRight {
    width: 60%;
    position: absolute;
    background: #fff;
    right: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 100;
  }

  .navbarRight>li {
    padding-left: 10px;
    border-bottom: 1px solid #f7b4cb;
  }

  .visitBtn {
    font-size: 13px;
    margin-top: 10px;
  }

  .visitBtnDark {
    width: 100px;
    padding: 5px;
    font-size: 13px;
  }

  .home_floral_pattern::before {
    height: 560px;
  } 
}