.home_pattern {
    position: relative;
}

.home_pattern::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 130px;
    width: 80%;
    height: 515px;
    background: url(../../assets/wedding-items.svg) no-repeat center center / cover;
    z-index: -1;
    overflow: hidden;
}

.homepage_text {
    margin-top: 60px;
    text-align: center;
}

.homepage_text {
    position: relative;
}

.iconContainer {
    position: absolute;
    top: 138px;
    right: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconContainer a{
    margin-bottom: 15px;
}

.icon {
    width: 35px;
    height: 35px;
    
}

.iconContainer a:last-child {
    margin-bottom: 0;
}

.homepage_text h1 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
    color: var(--secondary-color);
}

.homepage_text p {
    font-size: 18px;
    font-weight: 300;
    color: var(--primary-black);
}

.homepage_img {
    margin-top: 20px;
}

.homepage_img img {
    height: 340px;
}

.aboutus_Img {
    margin-top: 40px;
}

.text_heading {
    text-align: center;
}

.text_heading h2 {
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--secondary-color);
}

.text_heading p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: var(--primary-black);
}


.aboutus_Image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
}

.aboutus_Image::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 2px solid white;
    pointer-events: none;
    border-radius: 20px;
}

.aboutus_Image img {
    display: block;
    border-radius: 20px;
}

.aboutus_Image h5 {
    position: absolute;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
}

.home_gallery_div {
    background: linear-gradient(90deg, rgba(236, 0, 140, 0.1) 0%, rgba(252, 103, 103, 0.1) 100%);
    padding: 100px 0px;
}

.home_gallery {
    margin-top: 40px;
}

.home_sevices_div .text_heading {
    text-align: left;
}

.home_sevices_div img {
    border-radius: 40px;
}

.services_heading {
    margin-top: 20px;
}

.services_heading h5 {
    font-size: 20px;
    line-height: 30px;
    color: var(--primary-black);
    font-weight: 500;
}

.services_heading p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: var(--primary-black)
}

.portfolio_div {
    background: linear-gradient(90deg, rgba(236, 0, 140, 0.1) 0%, rgba(252, 103, 103, 0.1) 100%);
    padding: 100px 0px;
}

.portfolio_card:nth-child(odd) img {
    border-radius: 180px 0px 0px 0px;
}

.portfolio_card:nth-child(2) img {
    border-radius: 0px 0px 0px 180px;
}

.portfolio_card:nth-child(3) .portfolio_last_card {
    margin-top: 100px;
}

.portfolio_div .text_heading {
    text-align: right;
}

.portfolio_heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.portfolio_heading h5 {
    font-size: 20px;
    line-height: 30px;
    color: var(--primary-color);
    font-weight: 500;
}

.portfolio_heading span {
    font-size: 15px;
    line-height: 25px;
    color: var(--primary-black);
    opacity: 0.5;
    font-weight: 400;
}

.portfolio_card p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: var(--primary-black)
}

.form {
    align-items: center;
}

.form_left {
    background: linear-gradient(90deg, rgba(236, 0, 140, 0.1) 0%, rgba(252, 103, 103, 0.1) 100%);
    position: relative;
    padding: 40px 0px 0px 40px;
    border-radius: 20px;
}

.form_left::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background: url(../../assets/flower-pattern.svg) no-repeat center center / cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.form_left .text_heading {
    text-align: left;
}

.form_left .text_heading h2 {
    color: var(--primary-color);
}

.form_left .text_heading p:nth-child(2) {
    margin-bottom: 0px;
}

.form_left .couple {
    display: flex;
    justify-content: center;
    height: 330px;
}

.form_right {
    padding: 40px;
}


@media screen and (max-width: 768px) {

    .homepage_text h1 {
        font-size: 24px;
        line-height: 34px;
    }

    .homepage_text p {
        font-size: 16px;
        font-weight: 300;
    }

    .iconContainer {
        top: 158px;
        right: 25px;
    }
    
    .icon {
        width: 30px;
        height: 30px;
    }

    .home_pattern::before {
        display: none;
    }

    .homepage_img img {
        height: 200px;
    }

    .text_heading h2 {
        font-size: 24px;
        line-height: 34px;
        text-align: center;
    }

    .text_heading p {
        font-size: 16px;
        line-height: 26px;
        margin: 0;
    }

    .aboutus_Img {
        margin-top: 0px;
    }

    .aboutus_Image {
        margin-top: 40px;
    }

    .aboutus_Image img {
        display: block;
        border-radius: 10px;
        height: 350px;
    }

    .aboutus_Image::before {
        content: "";
        position: absolute;
        top: 6px;
        left: 35px;
        right: 35px;
        bottom: 6px;
        border: 2px solid white;
        pointer-events: none;
        border-radius: 10px;
    }

    .home_gallery_div {
        padding: 60px 0px;
    }

    .home_sevices_div .text_heading {
        text-align: center;
    }

    .home_sevices_div img {
        border-radius: 10px;
        height: 365px;
    }

    .services_heading {
        text-align: center;
    }

    .services_heading h5 {
        font-size: 18px;
        line-height: 28px;
        margin-top: 20px;
    }

    .services_heading p {
        font-size: 16px;
        line-height: 26px;
        text-align: justify;
        margin: 0;
    }

    .portfolio_div {
        padding: 60px 0px;
    }

    .portfolio_card {
        margin-top: 20px;
    }

    .portfolio_card:nth-child(odd) img {
        border-radius: 10px;
    }

    .portfolio_card:nth-child(2) img {
        border-radius: 10px;
    }

    .portfolio_heading h5 {
        font-size: 18px;
        line-height: 28px;
    }

    .portfolio_heading span {
        font-size: 14px;
        line-height: 24px;
    }

    .portfolio_card p {
        font-size: 16px;
        line-height: 26px;
    }

    .portfolio_card:nth-child(3) .portfolio_last_card {
        margin-top: 0;
    }

    .form_right {
        padding: 20px;
    }

    .form_right .mt40 {
        margin-top: 20px;
    }
}